.socialMedia {
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: -30px;
  margin-bottom: 15px;
}
.solicalMedia__link {
  margin-left: 10px;
  margin-right: 10px;
  font-size: 45px;
}
.socialMedia__icon {
  color: white;
  z-index: 100;
}
@media only screen and (max-width: 1000px) {
  .socialMedia {
    margin-top: -15px;
  }
}
@media only screen and (max-height: 400px) {
  .socialMedia {
    z-index: 100;
  }
}
