.videosPage {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}
.videosPage__header {
  height: auto;
}
@media only screen and (max-width: 1100px) {
  .videosPage__footer {
    margin-bottom: 20px;
  }
}
@media only screen and (max-width: 500px) {
  .videosPage__header {
    margin-top: -10px;
    margin-bottom: 1px;
  }
}
@media only screen and (max-height: 400px) {
  .videosPage__footer {
    margin-bottom: 20px;
  }
}
