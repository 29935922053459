.pledge {
  color: white;
  font-size: 25px;
  margin: 7%;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.pledgeTitle {
  font-size: 60px !important;
  margin-bottom: 10px;
  width: 30%;
  padding-left: 5%;
  text-align: right;
  margin-right: 40px;
}
.pledgeDetails {
  text-align: left;
}
@media only screen and (max-width: 900px) {
  .pledge {
    margin: 7%;
  }
}
@media only screen and (max-width: 750px) {
  .pledgeTitle {
    font-size: 40px !important;
  }
}
@media only screen and (max-width: 500px) {
  .pledge {
    margin: 5%;
    font-size: 20px;
  }
  .pledgeTitle {
    font-size: 33px !important;
  }
}
