.receptionSection {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-bottom: 20px;
}
.receptionSection__details {
  color: white;
  margin-top: 4px;
  margin-bottom: 4px;
  font-size: 22px;
}
