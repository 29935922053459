.videosBody {
  width: 100%;
}
.videosBody__row {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
}
@media only screen and (max-width: 1100px) {
  .videosBody__row {
    flex-direction: column;
    width: 100%;
  }
}
@media only screen and (max-width: 500px) {
  .videosBody {
    margin-bottom: 0px;
  }
}
@media only screen and (max-height: 400px) {
  .videosBody__row {
    flex-direction: column;
  }
}
