.inquiryForm {
  display: flex;
  flex-direction: column;
  width: 700px;
  align-items: center;
  margin-bottom: 10px;
  z-index: 100;
}
.inquiryForm__input {
  color: white;
  padding: 5px;
  font-size: 25px;
  width: 500px;
  text-align: center;
  margin: 5px;
  padding: 1%;
}
.inquiryForm__submitBtn {
  height: 80px;
  width: 30%;
  color: whitesmoke;
  background: none;
  border-color: whitesmoke;
  border-radius: 5px;
  align-content: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 3%;
  margin-right: 3%;
  cursor: pointer;
  font-size: 25px;
}
@media only screen and (max-width: 500px) {
  .inquiryForm {
    width: 80%;
    padding: 8px;
    font-size: 30px;
  }
  .inquiryForm__input {
    width: 95%;
    padding: 2%;
    margin: 2%;
    text-align: left;
  }
  .inquiryForm__submitBtn {
    width: 90%;
    height: 60px;
  }
}
