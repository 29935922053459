.servicesBody {
  width: 100%;
  align-items: center;
  justify-content: center;
}
.servicesBody__container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
}
.servicesBody__container2 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
}
.servicesBody__title {
  color: gold;
  font-size: 35px;
  border: gold;
  border-style: solid;
  border-width: 3px;
  padding: 5px;
  font-weight: 600;
  padding-left: 40px !important;
  padding-right: 40px !important;
  border-radius: 5px;
}
.servicesBody__subtitle {
  color: white;
  font-size: 22px;
  margin-top: 10px;
}
.servicesBody__sectionBody1 {
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.servicesBody__column1 {
  width: 48%;
  margin: 2%;
}
.servicesBody__column2 {
  display: flex;
  flex-direction: column;
  width: 45%;
  margin: 2%;
}
.servicesBody__image {
  object-fit: contain;
  max-width: 100%;
  margin-top: 30px;
}
.servicesBody__sectionBody3 {
  width: 90%;
  justify-content: space-around;
}
.servicesBody__row {
  display: flex;
  width: 100%;
}
.servicesBody__column3 {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 50%;
}
.servicesBody__sectionBody2 {
  width: 90%;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.videos__video {
  margin: 2%;
  max-width: 50%;
  height: auto;
}
.videoTitle {
  color: white;
  width: 100%;
  font-size: 22px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 20px;
}
.video {
  max-width: 100%;
  max-height: 100%;
}
@media only screen and (max-width: 1100px) {
  .servicesBody__column3 {
    flex-direction: column;
    justify-content: none;
    display: flex;
  }
  .servicesBody__sectionBody3 {
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .servicesBody__row {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
  }
  .servicesBody__sectionBody2 {
    width: 95%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .videos__video {
    margin: 2%;
    max-width: 100%;
    height: auto;
  }
  .servicesBody__column2 {
    width: 90%;
  }
}
@media only screen and (max-width: 900px) {
  .servicesBody__column1 {
    width: 95%;
  }
  #mainSection1 {
    flex-direction: column;
    text-align: center;
  }
  .servicesBody__image {
    margin-bottom: 30px;
  }
}
@media only screen and (max-width: 750px) {
  .servicesBody__column2 {
    display: flex;
    width: 100%;
  }
  .video {
    max-width: 100%;
  }
  .servicesBody__container2 {
    margin-bottom: 0px;
  }
}
@media only screen and (max-width: 500px) {
  .servicesBody__row {
    display: flex;
    width: 100%;
    align-items: center;
    justify-items: center;
    flex-direction: column;
    text-align: center;
  }
  .servicesBody__column3 {
    width: 100%;
    align-items: center;
    justify-content: center;
  }
  .servicesBody__container {
    width: 100%;
    justify-content: center;
  }
  .servicesBody__sectionBody3 {
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .servicesBody__column2 {
    width: 100%;
  }
  .videoTitle {
    left: 0px;
    top: 17px;
  }
  .servicesBody__title {
    font-size: 30px;
  }
  .servicesBody__subtitle {
    font-size: 16px;
  }
}
