.headerLinks {
  display: flex;
  flex-direction: row;
}
.headerLinks__link {
  width: 120px;
  height: 60px;
  margin-left: 20px;
  margin-right: 20px;
}
.headerLinks__button {
  border: none;
  height: 100%;
  width: 100%;
  cursor: pointer;
  background: none;
}
.headerLinks__buttonText {
  color: whitesmoke;
  font-size: 20px;
}
@media only screen and (max-width: 950px) {
  .headerLinks {
    display: none;
  }
}
@media only screen and (max-height: 400px) {
  .headerLinks {
    display: none;
  }
}
