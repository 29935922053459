@import url(https://fonts.googleapis.com/css2?family=Montserrat&display=swap);
.fullPageImage {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}
.fullPageImage__image {
  width: 100%;
  display: flex;
}

.headerIcon {
  width: 350px;
  margin-left: 2%;
}
.headerIcon__button {
  border: none;
  height: 100%;
  width: 100%;
  cursor: pointer;
  background: none;
  margin: 3px;
}
.headerIcon__image {
  object-fit: contain;
  max-width: 100%;
  height: auto;
}
@media only screen and (max-width: 950px) {
  .headerIcon {
    width: 50%;
    margin-left: 2%;
  }
}

.headerLinks {
  display: flex;
  flex-direction: row;
}
.headerLinks__link {
  width: 120px;
  height: 60px;
  margin-left: 20px;
  margin-right: 20px;
}
.headerLinks__button {
  border: none;
  height: 100%;
  width: 100%;
  cursor: pointer;
  background: none;
}
.headerLinks__buttonText {
  color: whitesmoke;
  font-size: 20px;
}
@media only screen and (max-width: 950px) {
  .headerLinks {
    display: none;
  }
}
@media only screen and (max-height: 400px) {
  .headerLinks {
    display: none;
  }
}

.mobileMenu {
  display: none;
  width: 100%;
}
.mobileMenu__link {
  margin: 10px;
}
.mobileMenu__button {
  color: white;
  padding: 7px;
  border: none;
  cursor: pointer;
}
.mobileMenu__buttonText {
  color: whitesmoke;
  font-size: 20px;
}
@media only screen and (max-width: 950px) {
  .mobileMenu {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
  .mobileMenu__buttonText {
    font-size: 18px;
    font-weight: 600;
  }
}

.mobileMenuButton {
  display: none;
}
.mobileMenuButton__button {
  border: none;
  color: white;
  width: 100px;
  height: 50px;
  z-index: 1000;
}
.mobileMenuButton__icon {
  color: white;
  cursor: pointer;
}
@media only screen and (max-width: 950px) {
  .mobileMenuButton {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
}
@media only screen and (max-height: 400px) {
  .mobileMenuButton {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
}

* {
  margin: 0;
}
.headerBody {
  height: 100%;
  width: 98vw;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.headerBody__container {
  margin: 10px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.headerBody__links {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-right: 2%;
}
.headerBody__mobileMenu {
  display: none;
}
@media only screen and (max-width: 950px) {
  .headerBody__container {
    margin-bottom: -7px;
  }
  .headerBody__mobileMenu {
    display: flex;
    align-items: center;
  }
}
@media only screen and (max-height: 400px) {
  .headerBody__container {
    margin-bottom: -20px;
  }
  .headerBody {
    padding-bottom: 10px;
  }
}

.banner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
}
.banner__image {
  display: flex;
  width: 100%;
}

.tagline {
  width: 90%;
  display: flex;
  justify-content: center;
  color: white;
  align-items: center;
}
.tagline__title {
  font-size: 90px;
  margin: 3%;
  width: 45%;
  text-align: right;
  font-weight: 1000;
}
.tagline__subtitle {
  font-size: 35px;
  flex-wrap: wrap;
  width: 45%;
  margin: 2%;
  display: flex;
  flex-direction: row;
}
.tagline__subtitleText1 {
  margin-right: 5px;
}
.tagline__subtitleText2 {
  font-weight: 600;
  color: gold;
}
@media only screen and (max-width: 1000px) {
  .tagline__title {
    font-size: 70px;
  }
  .tagline__subtitle {
    width: 35%;
    text-align: left;
    flex-direction: column;
  }
  .tagline__subtitleText1 {
    width: 70%;
  }
  .tagline__subtitleText2 {
    width: 70%;
  }
}
@media only screen and (max-width: 900px) {
  .tagline__subtitleText1 {
    width: 100%;
  }
  .tagline__subtitleText2 {
    width: 100%;
  }
}
@media only screen and (max-width: 750px) {
  .tagline {
    width: 90%;
  }
  .tagline__title {
    font-size: 45px;
    width: 48%;
  }
  .tagline__subtitle {
    width: 50%;
    margin-left: 50px;
    font-size: 27px;
  }
}
@media only screen and (max-width: 500px) {
  .tagline {
    margin-top: 55px;
    flex: 1 1;
    width: 100%;
    margin-left: 10%;
  }
  .tagline__title {
    font-size: 35px;
    width: 48%;
  }
  .tagline__subtitle {
    font-size: 18px;
    margin-left: 15px;
    width: 60%;
  }
}
@media only screen and (max-height: 400px) {
  .tagline {
    margin-top: 20px;
    margin-bottom: 15px;
    margin-left: 10%;
  }
}

.imageRow {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
}
.imageRow__image {
  max-width: 45%;
}
@media only screen and (max-width: 900px) {
  .imageRow {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .imageRow__image {
    max-width: 90%;
    margin-top: 15px;
    margin-bottom: 15px;
  }
}
@media only screen and (max-height: 400px) {
  .imageRow {
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
  }
  .imageRow__image {
    max-width: 45%;
    margin-top: 0px;
    margin-bottom: 15px;
  }
}

.pledge {
  color: white;
  font-size: 25px;
  margin: 7%;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.pledgeTitle {
  font-size: 60px !important;
  margin-bottom: 10px;
  width: 30%;
  padding-left: 5%;
  text-align: right;
  margin-right: 40px;
}
.pledgeDetails {
  text-align: left;
}
@media only screen and (max-width: 900px) {
  .pledge {
    margin: 7%;
  }
}
@media only screen and (max-width: 750px) {
  .pledgeTitle {
    font-size: 40px !important;
  }
}
@media only screen and (max-width: 500px) {
  .pledge {
    margin: 5%;
    font-size: 20px;
  }
  .pledgeTitle {
    font-size: 33px !important;
  }
}

.whatsOffered {
  color: white;
  margin-bottom: 25px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.whatsOffered__title {
  font-size: 75px;
  margin: 2%;
  width: 50%;
  font-weight: 1000;
  text-align: end;
}
.whatsOffered__detailContainer {
  display: flex;
  flex-direction: column;
  width: 35%;
  text-align: left;
}
.whatsOffered__detail {
  font-size: 30px;
  margin: 2%;
  width: 100%;
}
@media only screen and (max-width: 900px) {
  .whatsOffered__title {
    font-size: 50px;
  }
}
@media only screen and (max-width: 750px) {
  .whatsOffered__title {
    font-size: 35px;
  }
  .whatsOffered__detail {
    font-size: 20px;
  }
}
@media only screen and (max-width: 500px) {
  .whatsOffered {
    margin-bottom: 35px;
  }
  .whatsOffered__detailContainer {
    width: 50%;
  }
  .whatsOffered__detail {
    font-size: 17px;
  }
}

.homeBottom {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.songListButton {
  height: 100px;
  width: 40%;
  color: whitesmoke;
  background: none;
  border-color: whitesmoke;
  border-width: 2px;
  z-index: 150;
  border-radius: 5px;
  align-content: center;
  justify-content: center;
  margin-left: 3%;
  margin-right: 3%;
  cursor: pointer;
  font-size: 20px;
  font-weight: bold;
}
.songListButton__text {
  color: whitesmoke;
  font-size: 60px;
}
@media only screen and (max-width: 1070px) {
  .songListButton {
    width: 80%;
  }
  .songListButton__text {
    font-size: 40px;
  }
}
@media only screen and (max-width: 500px) {
  .songListButton {
    width: 90%;
    margin-top: 35px;
  }
}

.homeBody {
  display: flex;
  flex-direction: column;
  width: 100%;
  color: white;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  text-align: center;
}

.loadingSpinner {
  display: flex;
  justify-content: center;
  margin-top: 175px;
  margin-bottom: 300px;
}
@media only screen and (max-width: 800px) {
  .loadingSpinner {
    margin-top: 200px;
    margin-bottom: 500px;
  }
}
@media only screen and (max-width: 500px) {
  .loadingSpinner {
    margin-bottom: 400px;
  }
}
@media only screen and (max-height: 400px) {
  .loadingSpinner {
    margin-top: 13px;
  }
}

.video {
  margin: 2%;
  max-width: 45%;
  height: auto;
}
.video__title {
  color: white;
  width: 100%;
  font-size: 22px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 20px;
}
.video__player {
  width: 640px;
  height: 360px;
}
.homeVideo__player {
  width: 840px;
  height: 472px;
}
@media only screen and (max-width: 1400px) and (min-width: 1101px) {
  .video__player {
    width: 540px;
    height: 304px;
  }
}
@media only screen and (max-width: 1100px) {
  .video {
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .homeVideo__player {
    width: 640px;
    height: 360px;
  }
}
@media only screen and (max-width: 700px) {
  .video__player {
    width: 500px;
    height: 281px;
  }
  .homeVideo__player {
    width: 500px;
    height: 281px;
  }
}
@media only screen and (max-width: 500px) {
  .video {
    position: relative;
    text-align: center;
    color: white;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .video__title {
    position: absolute;
    left: 0px;
    top: 17px;
  }
  .video__player {
    width: 375px;
    height: 210px;
  }
  .homeVideo__player {
    width: 375px;
    height: 210px;
  }
}
@media only screen and (max-height: 400px) {
  .videos__video {
    align-items: center;
    justify-content: center;
    max-width: 99%;
    margin-bottom: 30px;
  }
}

.footerButton {
  height: 100px;
  width: 20%;
  color: whitesmoke;
  background: none;
  border-color: whitesmoke;
  border-radius: 5px;
  align-content: center;
  justify-content: center;
  margin-left: 3%;
  margin-right: 3%;
  cursor: pointer;
  font-size: 20px;
  font-weight: bold;
}
.footerButton__text {
  color: whitesmoke;
  text-decoration: none;
}
@media only screen and (max-width: 800px) {
  .footerButton {
    height: 60px;
    width: 70%;
    margin-top: 5px;
    margin-bottom: 22px;
  }
}
@media only screen and (max-width: 720px) {
}
@media only screen and (max-height: 400px) {
  .footerButton {
    height: 60px;
    width: 55%;
    margin: 12px;
  }
}

.copyrightAndCredit {
  margin-top: -10px;
  font-size: 12px;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  text-align: center;
}
.copyrightAndCredit__text {
  margin: 2px;
}
@media only screen and (max-width: 720px) {
}
@media only screen and (max-width: 800px) {
  .copyrightAndCredit {
    flex-direction: row;
    justify-content: space-between;
  }
  .copyrightAndCredit__text {
    font-size: 11px;
    z-index: 150;
    margin: 5px;
  }
}
@media only screen and (max-height: 400px) {
  .copyrightAndCredit {
    width: 90%;
    flex-direction: row;
    justify-content: space-between;
  }
}

.footerIcon {
  object-fit: contain;
  width: 100%;
  height: auto;
  z-index: 100;
}
@media only screen and (max-width: 800px) {
  .footerIcon {
    margin-bottom: -10px;
    width: 50%;
  }
}
@media only screen and (max-height: 400px) {
  .footerIcon {
    width: 75%;
    margin-bottom: -20px;
  }
}

.socialMedia {
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: -30px;
  margin-bottom: 15px;
}
.solicalMedia__link {
  margin-left: 10px;
  margin-right: 10px;
  font-size: 45px;
}
.socialMedia__icon {
  color: white;
  z-index: 100;
}
@media only screen and (max-width: 1000px) {
  .socialMedia {
    margin-top: -15px;
  }
}
@media only screen and (max-height: 400px) {
  .socialMedia {
    z-index: 100;
  }
}

.iconSocialCredit {
  margin-left: 3%;
  margin-right: 3%;
  margin-bottom: 2%;
  height: 100%;
  width: 23%;
  align-items: center;
  display: flex;
  flex-direction: column;
}
@media only screen and (max-width: 800px) {
  .iconSocialCredit {
    width: 100%;
    z-index: 100;
    margin-top: -15px;
  }
}
@media only screen and (max-height: 400px) {
  .iconSocialCredit {
    width: 100%;
    z-index: 90;
    margin-top: -20px;
  }
}

.taglineAndContact {
  font-size: 20px;
  margin-left: 3%;
  margin-right: 3%;
  align-items: center;
  display: flex;
  width: 500px;
  text-align: center;
  flex-direction: column;
}
.taglineAndContact__info {
  margin: 5px;
}
.taglineAndContact__infoContact {
  margin: 7px;
}
.taglineAndContact__infoClick {
  color: white;
  text-decoration: none;
}
@media only screen and (max-width: 800px) {
  .taglineAndContact {
    font-size: 18px;
    z-index: 200;
    width: 100%;
    margin-top: 10px;
  }
  .taglineAndContact__infoContact {
    font-size: 19px;
  }
  .taglineAndContact__info {
    margin: 3px;
  }
}
@media only screen and (max-height: 400px) {
  .taglineAndContact {
    z-index: 100;
    margin-top: 20px;
  }
}

.footerBody {
  display: flex;
  height: auto;
  width: 98vw;
  display: flex;
  color: whitesmoke;
  align-items: center;
  justify-content: center;
  z-index: 100;
}
@media only screen and (max-width: 720px) {
  /* .footerBody {
      display: flex;
      height: 95px;
      flex-direction: column-reverse;
      padding-bottom: 10px;
    } */
}
@media only screen and (max-width: 800px) {
  .footerBody {
    flex-direction: column-reverse;
  }
}
@media only screen and (max-height: 400px) {
  .footerBody {
    flex-direction: column-reverse;
  }
}

.homePage {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}
.homePage__header {
  height: auto;
}
@media only screen and (max-width: 900px) {
}
@media only screen and (max-width: 1000px) {
}
@media only screen and (max-width: 500px) {
  .homePage {
    overflow-x: hidden;
  }
  .homePage__header {
    width: inherit;
    margin-top: -10px;
    margin-bottom: 1px;
  }
  .homePage__footer {
    width: inherit;
    margin-top: 25px;
    margin-bottom: 20px;
    flex: 1 1;
  }
}
@media only screen and (max-height: 400px) {
  .homePage__header {
    margin-bottom: 1px;
  }
  .homePage__footer {
    margin-top: 25px;
    margin-bottom: 7px;
  }
}
@media only screen and (max-height: 750px) and (min-width: 1000px),
  (min-width: 1200px) {
}

.sectionTitle {
  height: 35px;
  margin-top: 8px;
  margin-bottom: 8px;
}
.sectionTitle__text {
  height: 100%;
  width: 100%;
  font-size: 30px;
  color: white;
  background: none;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
}
@media only screen and (max-width: 500px) {
  .sectionTitle__text {
    font-size: 23px;
  }
}

.receptionSection {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-bottom: 20px;
}
.receptionSection__details {
  color: white;
  margin-top: 4px;
  margin-bottom: 4px;
  font-size: 22px;
}

.infoForAll {
  width: 90%;
  margin-left: 5%;
  display: flex;
  flex-direction: column;
}
.infoForAll__title {
  font-size: 30px;
  color: white;
  /* text-align: center; */
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
}
.infoForAll__details {
  color: white;
  margin: 4px;
  font-size: 22px;
}
@media only screen and (max-width: 500px) {
  .infoForAll__title {
    text-align: center;
    font-size: 25px;
  }
}

.cocktailSection {
  margin-bottom: 5px;
  margin-top: 5px;
}
.cocktailSection__title {
  text-align: center;
}
.cocktailSection__details {
  color: white;
  margin-top: 4px;
  margin-bottom: 4px;
  font-size: 22px;
}

.ceremonySection {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.ceremonySection__titleContainer {
  text-align: center;
}
.ceremonySection__details {
  color: white;
  margin-top: 4px;
  margin-bottom: 4px;
  font-size: 22px;
}

.servicesBody {
  width: 100%;
  align-items: center;
  justify-content: center;
}
.servicesBody__container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
}
.servicesBody__container2 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
}
.servicesBody__title {
  color: gold;
  font-size: 35px;
  border: gold;
  border-style: solid;
  border-width: 3px;
  padding: 5px;
  font-weight: 600;
  padding-left: 40px !important;
  padding-right: 40px !important;
  border-radius: 5px;
}
.servicesBody__subtitle {
  color: white;
  font-size: 22px;
  margin-top: 10px;
}
.servicesBody__sectionBody1 {
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.servicesBody__column1 {
  width: 48%;
  margin: 2%;
}
.servicesBody__column2 {
  display: flex;
  flex-direction: column;
  width: 45%;
  margin: 2%;
}
.servicesBody__image {
  object-fit: contain;
  max-width: 100%;
  margin-top: 30px;
}
.servicesBody__sectionBody3 {
  width: 90%;
  justify-content: space-around;
}
.servicesBody__row {
  display: flex;
  width: 100%;
}
.servicesBody__column3 {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 50%;
}
.servicesBody__sectionBody2 {
  width: 90%;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.videos__video {
  margin: 2%;
  max-width: 50%;
  height: auto;
}
.videoTitle {
  color: white;
  width: 100%;
  font-size: 22px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 20px;
}
.video {
  max-width: 100%;
  max-height: 100%;
}
@media only screen and (max-width: 1100px) {
  .servicesBody__column3 {
    flex-direction: column;
    justify-content: none;
    display: flex;
  }
  .servicesBody__sectionBody3 {
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .servicesBody__row {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
  }
  .servicesBody__sectionBody2 {
    width: 95%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .videos__video {
    margin: 2%;
    max-width: 100%;
    height: auto;
  }
  .servicesBody__column2 {
    width: 90%;
  }
}
@media only screen and (max-width: 900px) {
  .servicesBody__column1 {
    width: 95%;
  }
  #mainSection1 {
    flex-direction: column;
    text-align: center;
  }
  .servicesBody__image {
    margin-bottom: 30px;
  }
}
@media only screen and (max-width: 750px) {
  .servicesBody__column2 {
    display: flex;
    width: 100%;
  }
  .video {
    max-width: 100%;
  }
  .servicesBody__container2 {
    margin-bottom: 0px;
  }
}
@media only screen and (max-width: 500px) {
  .servicesBody__row {
    display: flex;
    width: 100%;
    align-items: center;
    justify-items: center;
    flex-direction: column;
    text-align: center;
  }
  .servicesBody__column3 {
    width: 100%;
    align-items: center;
    justify-content: center;
  }
  .servicesBody__container {
    width: 100%;
    justify-content: center;
  }
  .servicesBody__sectionBody3 {
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .servicesBody__column2 {
    width: 100%;
  }
  .videoTitle {
    left: 0px;
    top: 17px;
  }
  .servicesBody__title {
    font-size: 30px;
  }
  .servicesBody__subtitle {
    font-size: 16px;
  }
}

.servicesPage {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}
.servicesPage__header {
  height: auto;
}
.servicesPage__footer {
  height: auto;
}

@media only screen and (max-width: 1100px) {
}
@media only screen and (max-width: 900px) {
}
@media only screen and (max-width: 750px) {
}
@media only screen and (max-width: 500px) {
  .servicesPage__header {
    margin-top: -10px;
    margin-bottom: 1px;
  }
}

.videosBody {
  width: 100%;
}
.videosBody__row {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
}
@media only screen and (max-width: 1100px) {
  .videosBody__row {
    flex-direction: column;
    width: 100%;
  }
}
@media only screen and (max-width: 500px) {
  .videosBody {
    margin-bottom: 0px;
  }
}
@media only screen and (max-height: 400px) {
  .videosBody__row {
    flex-direction: column;
  }
}

.videosPage {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}
.videosPage__header {
  height: auto;
}
@media only screen and (max-width: 1100px) {
  .videosPage__footer {
    margin-bottom: 20px;
  }
}
@media only screen and (max-width: 500px) {
  .videosPage__header {
    margin-top: -10px;
    margin-bottom: 1px;
  }
}
@media only screen and (max-height: 400px) {
  .videosPage__footer {
    margin-bottom: 20px;
  }
}

.bandBio {
  width: 95%;
  display: flex;
  align-items: center;
  margin: 3%;
  color: white;
  font-size: 23px;
}
@media only screen and (max-width: 750px) {
  .bandBio {
    flex-direction: column;
  }
}
@media only screen and (max-width: 500px) {
  .bandBio {
    font-size: 20px;
  }
  .bandBio__text {
    margin-bottom: 17px;
  }
}
@media only screen and (max-height: 400px) {
  .bandBio {
    flex-direction: column;
  }
}

.isaacBio {
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 3%;
  margin-bottom: 3%;
  color: white;
  font-size: 23px;
}
.isaacBio__image {
  margin-left: 5%;
  margin-right: 3%;
  width: 40%;
}
.isaacBio__column {
  display: flex;
  flex-direction: column;
  margin-left: 3%;
  margin-right: 5%;
}
.isaacBio__name {
  font-size: 25px;
  font-weight: 700;
  margin-bottom: 10px;
}
@media only screen and (max-width: 750px) {
  .isaacBio {
    flex-direction: column;
  }
  .isaacBio__image {
    margin-top: 3%;
    width: 90%;
    margin-bottom: 5%;
  }
  .isaacBio__name {
    text-align: center;
  }
  .isaacBio__text {
    margin-bottom: 5%;
  }
}
@media only screen and (max-width: 500px) {
  .isaacBio {
    font-size: 20px;
  }
  .isaacBio__image {
    margin-right: 5%;
  }
  .isaacBio__text {
    margin-left: 5%;
  }
}
@media only screen and (max-height: 400px) {
  .isaacBio {
    flex-direction: column;
  }
  .isaacBio__image {
    width: 86%;
    margin-right: 7%;
  }
}

.aboutBody {
  width: 100%;
}

.aboutPage {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}
.aboutPage__header {
  height: auto;
}
.aboutPage__footer {
  height: auto;
}
@media only screen and (max-width: 750px) {
}
@media only screen and (max-width: 500px) {
  .aboutPage__header {
    margin-top: -10px;
    margin-bottom: 1px;
  }
}
@media only screen and (max-height: 400px) {
}

.inquiryForm {
  display: flex;
  flex-direction: column;
  width: 700px;
  align-items: center;
  margin-bottom: 10px;
  z-index: 100;
}
.inquiryForm__input {
  color: white;
  padding: 5px;
  font-size: 25px;
  width: 500px;
  text-align: center;
  margin: 5px;
  padding: 1%;
}
.inquiryForm__submitBtn {
  height: 80px;
  width: 30%;
  color: whitesmoke;
  background: none;
  border-color: whitesmoke;
  border-radius: 5px;
  align-content: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 3%;
  margin-right: 3%;
  cursor: pointer;
  font-size: 25px;
}
@media only screen and (max-width: 500px) {
  .inquiryForm {
    width: 80%;
    padding: 8px;
    font-size: 30px;
  }
  .inquiryForm__input {
    width: 95%;
    padding: 2%;
    margin: 2%;
    text-align: left;
  }
  .inquiryForm__submitBtn {
    width: 90%;
    height: 60px;
  }
}

.contactBody {
  display: flex;
  flex-direction: column;
  width: 100%;
  color: white;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  text-align: center;
}
.contactBody__headine {
  margin-top: 3% !important;
  margin: 2%;
  z-index: 100;
}
.contactBody__phone {
  font-size: 30px;
  z-index: 100;
}
.contactBody__click {
  text-decoration: none;
  color: white;
}
.contactBody__info {
  margin: 1%;
  font-size: 30px;
  z-index: 100;
}
.contactBody__info2 {
  margin-top: 1%;
  z-index: 100;
}
@media only screen and (max-width: 500px) {
  .contactBody {
    font-size: 20px;
  }
  .contactBody__phone {
    margin-top: 2%;
    margin-bottom: 2%;
  }
  .contactBody__info {
    margin-top: 2%;
    margin-bottom: 2%;
    font-size: 21px;
  }
  .contactBody__info2 {
    margin-top: 2%;
  }
}

.contactPage {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}
.contactPage__header {
  height: auto;
}
@media only screen and (max-width: 500px) {
  .contactPage__header {
    margin-top: -10px;
    margin-bottom: 1px;
  }
}

* {
  background-color: black;
  font-family: "Montserrat", sans-serif;
  margin: 0;
}
body > #root > div {
  width: 100%;
}
.app {
  display: flex;
  flex-direction: column;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

