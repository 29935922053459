@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");
* {
  background-color: black;
  font-family: "Montserrat", sans-serif;
  margin: 0;
}
body > #root > div {
  width: 100%;
}
.app {
  display: flex;
  flex-direction: column;
}
