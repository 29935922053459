.contactBody {
  display: flex;
  flex-direction: column;
  width: 100%;
  color: white;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  text-align: center;
}
.contactBody__headine {
  margin-top: 3% !important;
  margin: 2%;
  z-index: 100;
}
.contactBody__phone {
  font-size: 30px;
  z-index: 100;
}
.contactBody__click {
  text-decoration: none;
  color: white;
}
.contactBody__info {
  margin: 1%;
  font-size: 30px;
  z-index: 100;
}
.contactBody__info2 {
  margin-top: 1%;
  z-index: 100;
}
@media only screen and (max-width: 500px) {
  .contactBody {
    font-size: 20px;
  }
  .contactBody__phone {
    margin-top: 2%;
    margin-bottom: 2%;
  }
  .contactBody__info {
    margin-top: 2%;
    margin-bottom: 2%;
    font-size: 21px;
  }
  .contactBody__info2 {
    margin-top: 2%;
  }
}
