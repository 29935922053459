.fullPageImage {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}
.fullPageImage__image {
  width: 100%;
  display: flex;
}
