.servicesPage {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}
.servicesPage__header {
  height: auto;
}
.servicesPage__footer {
  height: auto;
}

@media only screen and (max-width: 1100px) {
}
@media only screen and (max-width: 900px) {
}
@media only screen and (max-width: 750px) {
}
@media only screen and (max-width: 500px) {
  .servicesPage__header {
    margin-top: -10px;
    margin-bottom: 1px;
  }
}
