.iconSocialCredit {
  margin-left: 3%;
  margin-right: 3%;
  margin-bottom: 2%;
  height: 100%;
  width: 23%;
  align-items: center;
  display: flex;
  flex-direction: column;
}
@media only screen and (max-width: 800px) {
  .iconSocialCredit {
    width: 100%;
    z-index: 100;
    margin-top: -15px;
  }
}
@media only screen and (max-height: 400px) {
  .iconSocialCredit {
    width: 100%;
    z-index: 90;
    margin-top: -20px;
  }
}
