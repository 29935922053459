.video {
  margin: 2%;
  max-width: 45%;
  height: auto;
}
.video__title {
  color: white;
  width: 100%;
  font-size: 22px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 20px;
}
.video__player {
  width: 640px;
  height: 360px;
}
.homeVideo__player {
  width: 840px;
  height: 472px;
}
@media only screen and (max-width: 1400px) and (min-width: 1101px) {
  .video__player {
    width: 540px;
    height: 304px;
  }
}
@media only screen and (max-width: 1100px) {
  .video {
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .homeVideo__player {
    width: 640px;
    height: 360px;
  }
}
@media only screen and (max-width: 700px) {
  .video__player {
    width: 500px;
    height: 281px;
  }
  .homeVideo__player {
    width: 500px;
    height: 281px;
  }
}
@media only screen and (max-width: 500px) {
  .video {
    position: relative;
    text-align: center;
    color: white;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .video__title {
    position: absolute;
    left: 0px;
    top: 17px;
  }
  .video__player {
    width: 375px;
    height: 210px;
  }
  .homeVideo__player {
    width: 375px;
    height: 210px;
  }
}
@media only screen and (max-height: 400px) {
  .videos__video {
    align-items: center;
    justify-content: center;
    max-width: 99%;
    margin-bottom: 30px;
  }
}
