.cocktailSection {
  margin-bottom: 5px;
  margin-top: 5px;
}
.cocktailSection__title {
  text-align: center;
}
.cocktailSection__details {
  color: white;
  margin-top: 4px;
  margin-bottom: 4px;
  font-size: 22px;
}
