.mobileMenuButton {
  display: none;
}
.mobileMenuButton__button {
  border: none;
  color: white;
  width: 100px;
  height: 50px;
  z-index: 1000;
}
.mobileMenuButton__icon {
  color: white;
  cursor: pointer;
}
@media only screen and (max-width: 950px) {
  .mobileMenuButton {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
}
@media only screen and (max-height: 400px) {
  .mobileMenuButton {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
}
