.headerIcon {
  width: 350px;
  margin-left: 2%;
}
.headerIcon__button {
  border: none;
  height: 100%;
  width: 100%;
  cursor: pointer;
  background: none;
  margin: 3px;
}
.headerIcon__image {
  object-fit: contain;
  max-width: 100%;
  height: auto;
}
@media only screen and (max-width: 950px) {
  .headerIcon {
    width: 50%;
    margin-left: 2%;
  }
}
